import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import logoIcon from '../assets/monstarx_logo_w.png';
import teamImage from '../assets/team_illustration.png';
import React from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

interface Screenshot {
  screen_description: string;
  screen_type: string;
  screen_url: string;
  image_base64?: string;
  step: number;
  timestamp: string;
}

interface WireframeData {
  screenshots: Screenshot[];
  metadata: {
    createdAt: string;
    expiresAt: string;
  };
}

export class WireframeGenerator {
  private static styles = StyleSheet.create({
    // Cover page styles
    coverPage: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      padding: 0,
      overflow: 'hidden',
    },
    topTriangle: {
      position: 'absolute',
      top: -200,
      left: -200,
      width: 400,
      height: 400,
      backgroundColor: '#F3F0FF',
      transform: 'rotate(45deg)',
      opacity: 0.8,
    },
    bottomTriangle: {
      position: 'absolute',
      bottom: -200,
      left: -200,
      width: 400,
      height: 400,
      backgroundColor: '#F3F0FF',
      transform: 'rotate(45deg)',
      opacity: 0.8,
    },
    contentContainer: {
      position: 'relative',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    logoContainer: {
      position: 'relative',
      width: '100%',
      paddingTop: 60,
      paddingBottom: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo: {
      width: 200,
    },
    illustrationContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 40,
      position: 'relative',
      zIndex: 1,
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 40,
      position: 'relative',
      zIndex: 1,
    },
    softwareTitle: {
      fontSize: 24,
      fontWeight: 'bold',
      color: '#5631B4',
      textAlign: 'center',
      fontFamily: 'Helvetica-Bold'
    },
    specTitle: {
      fontSize: 24,
      color: '#5631B4',
      fontFamily: 'Helvetica',
      opacity: 0.9,
    },
    illustration: {
      width: 300,
    },
    detailsContainer: {
      position: 'absolute',
      bottom: 70,
      right: 40,
      zIndex: 1,
      width: 300
    },
    reportDetails: {
      fontSize: 14,
      color: '#5631B4',
      textAlign: 'right',
      marginTop: 8,
      fontFamily: 'Helvetica',
      opacity: 0.8
    },

    // Content page styles
    page: {
      padding: 30,
      backgroundColor: '#FFFFFF',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      borderBottom: 1,
      borderBottomColor: '#EEEEEE',
      paddingBottom: 10,
    },
    headerLogo: {
      width: 100,
      height: 40,
      objectFit: 'contain',
    },
    contentWrapper: {
      flex: 1,
      position: 'relative',
      zIndex: 1,
    },
    screenTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#2E0E46',
      marginBottom: 15,
    },
    wireframeImage: {
      width: '90%',  // Slightly reduced to ensure margins
      height: 350,   // Fixed height
      marginHorizontal: 'auto',
      marginVertical: 20,
      objectFit: 'contain',
      border: '1px solid #EEEEEE',  // Light grey border
      borderRadius: 4,              // Optional: slightly rounded corners
      padding: 10,                  // Add some padding around the image
    },
    description: {
      fontSize: 12,
      color: '#333333',
      lineHeight: 1.5,
      marginTop: 15,
    },
  });

  private static PageHeader = () => (
    <View style={WireframeGenerator.styles.header}>
      <Image
        src={logoIcon}
        style={WireframeGenerator.styles.headerLogo}
      />
    </View>
  );

  private static async urlToBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function() {
        reject(new Error('Failed to load image'));
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  public static async generateWireframePDF(reportData: WireframeData) {
    console.log('Starting Wireframe PDF generation...');

    try {
      // Convert all screenshots to include base64 data
      const processedScreenshots = await Promise.all(
        reportData.screenshots.map(async (screenshot) => {
          try {
            const image_base64 = await this.urlToBase64(screenshot.screen_url);
            return {
              ...screenshot,
              image_base64
            } as Screenshot;
          } catch (error) {
            console.error(`Error processing image for screen ${screenshot.step}:`, error);
            // Return a placeholder base64 image if conversion fails
            return {
              ...screenshot,
              image_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' // 1x1 transparent PNG
            } as Screenshot;
          }
        })
      );

      const processedData: WireframeData = {
        ...reportData,
        screenshots: processedScreenshots
      };

      const WireframeDocument = () => (
        <Document>
          {/* Cover Page */}
          <Page 
            size="A4" 
            style={WireframeGenerator.styles.coverPage}
          >
            <View style={WireframeGenerator.styles.topTriangle} />
            <View style={WireframeGenerator.styles.bottomTriangle} />
            
            <View style={WireframeGenerator.styles.contentContainer}>
              <View style={WireframeGenerator.styles.logoContainer}>
                <Image 
                  style={WireframeGenerator.styles.logo}
                  src={logoIcon}
                />
              </View>
              
              <View style={WireframeGenerator.styles.illustrationContainer}>
                <Image 
                  style={WireframeGenerator.styles.illustration}
                  src={teamImage}
                />
              </View>

              <View style={WireframeGenerator.styles.titleContainer}>
                <Text style={WireframeGenerator.styles.softwareTitle}>
                  WIREFRAME
                </Text>
                <Text style={WireframeGenerator.styles.specTitle}>
                  SPECIFICATIONS
                </Text>
              </View>

              <View style={WireframeGenerator.styles.detailsContainer}>
                <Text style={WireframeGenerator.styles.reportDetails}>
                  Report Generated on: {new Date(reportData.metadata.createdAt).toLocaleDateString()}
                </Text>
              </View>
            </View>
          </Page>

          {/* Wireframe Pages */}
          {processedData.screenshots.map((screenshot, index) => (
            <Page key={`screen-${index}`} size="A4" style={WireframeGenerator.styles.page}>
              <WireframeGenerator.PageHeader />
              <View style={WireframeGenerator.styles.contentWrapper}>
                <Text style={WireframeGenerator.styles.screenTitle}>
                  Screen {index + 1}: {screenshot.screen_type.toUpperCase()}
                </Text>
                
                <Image
                  src={screenshot.image_base64}
                  style={WireframeGenerator.styles.wireframeImage}
                />
                
                <Text style={WireframeGenerator.styles.description}>
                  {screenshot.screen_description}
                </Text>
              </View>
            </Page>
          ))}

          {/* Thank You Page */}
          <Page 
            size="A4" 
            style={WireframeGenerator.styles.page}
          >
            <WireframeGenerator.PageHeader />
            <View style={[WireframeGenerator.styles.contentWrapper, { 
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }]}>
              <Text style={WireframeGenerator.styles.softwareTitle}>
                Thank You
              </Text>
            </View>

            <View style={{
              position: 'absolute',
              bottom: 40,
              right: 40,
              width: 100,
              height: 40
            }}>
              <Image src={logoIcon} style={WireframeGenerator.styles.headerLogo} />
            </View>
          </Page>
        </Document>
      );

      return WireframeDocument;
    } catch (error) {
      console.error('Error generating wireframe PDF:', error);
      throw error;
    }
  }
} 