import React from 'react';
import '../styles/SummarySideView.css';
import { FaTimes } from 'react-icons/fa';
import summary_icon from '../assets/summary_icon.png';
import deliverables from '../assets/deliverables.png';
import contact_details from '../assets/contact_details.png';
import session_end from '../assets/session_end.png';

interface SummarySideViewProps {
    isOpen: boolean;
    onClose: () => void;
    projectDetails: string;
    deliverables: string;
    emailId: string;
    onContinueChat: () => void;
    onEndSession: () => void;
}

// Helper function to format text with line breaks and emojis
const formatText = (text: string) => {
    return text.split('\n\n').map((paragraph, pIndex) => (
        <div key={`p-${pIndex}`} className="paragraph">
            {paragraph.split('\n').map((line, lIndex) => (
                <React.Fragment key={`l-${lIndex}`}>
                    {line}
                    {lIndex < paragraph.split('\n').length - 1 && <br />}
                </React.Fragment>
            ))}
        </div>
    ));
};

const SummarySideView: React.FC<SummarySideViewProps> = ({
    isOpen,
    onClose,
    projectDetails,
    deliverables: deliverablesList,
    emailId,
    onContinueChat,
    onEndSession
}) => {
    return (
        <>
            <div 
                className={`summary-overlay ${isOpen ? 'open' : ''}`}
                onClick={onClose}
            />
            <div className={`summary-side-view ${isOpen ? 'open' : ''}`}>
                <div className="summary-header">
                    <h2>Your AI-Generated Documents Are Almost Ready! What's Next?</h2>
                    <button className="summary-close-button" onClick={onClose}>
                        <FaTimes />
                    </button>
                </div>
                
                <div className="summary-content">
                    <div className="summary-item">
                        <div className="summary-icon">
                            <img src={summary_icon} alt="Project Details" />
                        </div>
                        <div className="summary-text">
                            <h3>Project Summary Collected</h3>
                            <p className="formatted-text">{formatText(projectDetails)}</p>
                        </div>
                    </div>

                    <div className="summary-item">
                        <div className="summary-icon">
                            <img src={deliverables} alt="Deliverables" />
                        </div>
                        <div className="summary-text">
                            <h3>AI is Preparing Following Documents</h3>
                            <p className="formatted-text">
                                {formatText(`\n📄 SRS Document (Structured Requirement Specification)\n\n🎨 Wireframes (Visual Blueprints)\n\n📝 Additional Key Points`)}
                            </p>
                        </div>
                    </div>

                    <div className="summary-item">
                        <div className="summary-icon">
                            <img src={contact_details} alt="Contact Details" />
                        </div>
                        <div className="summary-text">
                            <h3>Our Human consultant will send report to</h3>
                            <p className="formatted-text">{formatText(emailId)}</p>
                        </div>
                    </div>

                    <div className="summary-item">
                        <div className="summary-icon">
                            <img src={session_end} alt="End Session" />
                        </div>
                        <div className="summary-text">
                            <h3>MonstarX Team Will Reach Out</h3>
                            <p className="formatted-text">
                                {formatText("Our team will review your project and get in touch soon for next steps. 🚀\nWe're excited to work with you! ✨")}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="summary-actions">
                    <button 
                        className="end-session"
                        onClick={onEndSession}
                    >
                        End Session
                    </button>
                    <button 
                        className="continue-chat"
                        onClick={onContinueChat}
                    >
                        Continue Chat
                    </button>
                </div>
            </div>
        </>
    );
};

export default SummarySideView; 