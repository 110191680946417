import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

interface Screenshot {
  screen_description: string;
  screen_type: string;
  screen_url: string;
  image_base64?: string;
  step: number;
  timestamp: string;
}

interface WireframeReportData {
  screenshots: Screenshot[];
  metadata: {
    createdAt: string;
    expiresAt: string;
  };
}

interface WireframeApiResponse {
  status: string;
  message: string;
  data: WireframeReportData | null;
}

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': API_KEY,
  },
  withCredentials: true,
});

export class WireframeReportApi {
  static async generateWireframeReport(sessionToken: string): Promise<WireframeApiResponse> {
    try {
      const response = await api.post('/api/wireframe-report', {
        session_token: sessionToken
      });
      return response.data;
    } catch (error: any) {
      console.error('Error generating wireframe report:', error.response?.data || error.message);
      throw error;
    }
  }
} 