import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logoIcon from '../assets/monstarx_logo_w.png';
import teamImage from '../assets/team_illustration.png';
import frontendIcon from '../assets/front-end.png';
import backendIcon from '../assets/back-end.png';
import databaseIcon from '../assets/database.png';
import versionIcon from '../assets/version-control.png';
import testingIcon from '../assets/testing.png';
import deploymentIcon from '../assets/deployment.png';
// import twitterIcon from '../assets/twitter.png';
// import facebookIcon from '../assets/facebook.png';
// import youtubeIcon from '../assets/youtube.png';
// import linkedinIcon from '../assets/linkedin.png';
import React from 'react';

interface SRSData {
  step1: string;
  step2: string;
  step3: string;
  step4: string;
  step5: string;
  step6: string;
  step7: string;
  name: string;
  email: string;
  reportStatus: number;
  timestamp: string;
}

// Add interfaces for our data structures
interface SubSection {
  title: string;
  description: string[];
  storyPoints: number;
}

interface MainSection {
  title: string;
  description: string;
  storyPoints: number;
  subsections?: SubSection[];
}

interface TimelineItem {
  title: string;
  mainDescription?: string;
  duration?: string;
  targetDate?: string;
  activities?: string[];
  deliverables?: string[];
  details?: string[];
}

interface EstimationItem {
  feature: string;
  subItems: Array<{
    name: string;
    points: number;
  }>;
}

interface EstimationSummary {
  total: number;
  breakdowns: Array<{
    category: string;
    points: number;
  }>;
}

// First, add interface for TOC group items
interface TOCItem {
  text: string;
  level: 'main' | 'sub' | 'subsub';
  pageNumber: number;
}

// First, define interfaces for our data structure
interface Step2SubSubSection {
  title: string;
  content: string[];
}

interface Step2SubSection {
  title: string;
  content: string[];
  subsubsections: Step2SubSubSection[];
}

interface Step2Section {
  main: string;
  subsections: Step2SubSection[];
}

export class SRSGenerator {
  private static styles = StyleSheet.create({
    page: {
      padding: 0,
      fontSize: 12,
      fontFamily: 'Helvetica',
      lineHeight: 1.5,
      backgroundColor: '#FFFFFF',
      position: 'relative',
    },
    fixedHeader: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    tocTitle: {
      fontSize: 24,
      color: '#0F0F0F',
      marginBottom: 30,
      marginTop: 0,
      fontWeight: 900,
      fontFamily: 'Helvetica-Bold',
    },
    tocMainSection: {
      fontSize: 13,
      fontWeight: 400,
      marginTop: 15,
      marginBottom: 10,
      color: '#0F0F0F',
      fontFamily: 'Helvetica-Bold',
    },
    tocSubSection: {
      fontSize: 12,
      marginLeft: 30,
      marginTop: 4,
      marginBottom: 4,
      color: '#0F0F0F',
      fontWeight: 500,
      fontFamily: 'Helvetica',
    },
    tocSubSubSection: {
      fontSize: 12,
      color: '#0F0F0F',
      marginLeft: 40,
      marginTop: 4,
      marginBottom: 4,
      fontWeight: 500,
      fontFamily: 'Helvetica',
    },
    sectionTitle: {
      fontSize: 22,
      color: '#000000',
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
      marginTop: 10,
      marginBottom: 25,
      paddingLeft: 20,
      breakBefore: 'page',
    },
    subSectionContainer: {
      marginHorizontal: 20,
      marginVertical: 10,
      borderRadius: 8,
      minHeight: 32,
      borderWidth: 1,
      borderColor: '#E0CFFF',
      alignSelf: 'flex-start',
      // Remove display: 'flex' and breakInside
    },
    subSectionBackground: {
      backgroundColor: '#D9B0FF1A',
      paddingHorizontal: 16,
      paddingVertical: 6,
      borderRadius: 8,
      // Remove height: '100%'
    },
    subSectionTitle: {
      fontSize: 16,
      color: '#9756D2',
      fontFamily: 'Helvetica-Bold',
      // Remove fontWeight as it's handled by fontFamily
    },
    subSubSectionTitle: {
      fontSize: 14,
      color: '#9756D2',
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
      marginTop: 15,
      marginBottom: 10,
      paddingLeft: 40,
    },
    contentText: {
      fontSize: 12,
      color: '#000000',
      marginBottom: 12,
      lineHeight: 1.4,
      fontFamily: 'Helvetica',
      paddingLeft: 60,
      paddingRight: 40,
    },
    bulletPoint: {
      fontSize: 12,
      color: '#000000',
      marginBottom: 10,
      lineHeight: 1.4,
      fontFamily: 'Helvetica',
      paddingLeft: 60,
      paddingRight: 40,
      display: 'flex',
      flexDirection: 'row',
    },
    // Cover page styles
    coverPage: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      padding: 0,
      overflow: 'hidden',
    },
    // Top-left triangle gradient
    topTriangle: {
      position: 'absolute',
      top: -200,
      left: -200,
      width: 400,
      height: 400,
      backgroundColor: '#F3F0FF',
      transform: 'rotate(45deg)',
      opacity: 0.8,
    },
    // Bottom-left triangle gradient (only this position changed)
    bottomTriangle: {
      position: 'absolute',
      bottom: -200,
      left: -200,
      width: 400,
      height: 400,
      backgroundColor: '#F3F0FF',
      transform: 'rotate(45deg)',
      opacity: 0.8,
    },
    // Content containers
    contentContainer: {
      position: 'relative',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    // Logo container
    logoContainer: {
      position: 'relative',
      width: '100%',
      paddingTop: 100,
      paddingBottom: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo: {
      width: 200,
    },
    // Illustration container
    illustrationContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 50,
      marginBottom: 20,
      position: 'relative',
      zIndex: 1,
    },
    illustration: {
      width: 300,
    },
    // Title container
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 40,
      position: 'relative',
      zIndex: 1,
    },
    softwareTitle: {
      fontSize: 24,
      fontWeight: 'bold',
      color: '#5631B4',
      textAlign: 'center',
      fontFamily: 'Helvetica-Bold'
    },
    specTitle: {
      fontSize: 24,
      color: '#5631B4',
      fontFamily: 'Helvetica',
      opacity: 0.9,
    },
    // Add triangle styles for content pages
    topTriangleContent: {
      position: 'absolute',
      top: -150,
      right: -150,
      width: 400,
      height: 400,
      backgroundColor: '#F3F0FF',
      transform: 'rotate(45deg)',
      opacity: 0.8,
      zIndex: 0,
    },
    bottomTriangleContent: {
      position: 'absolute',
      bottom: -200,
      left: -200,
      width: 400,
      height: 400,
      backgroundColor: '#F3F0FF',
      transform: 'rotate(45deg)',
      opacity: 0.8,
      zIndex: 0,
    },
    bottomTriangleContentNewPage: {
        position: 'absolute',
        bottom: -50,
        right: -50,
        width: 100,
        height: 100,
        backgroundColor: '#F3F0FF',
        transform: 'rotate(45deg)',
        opacity: 0.8,
        zIndex: 0,
      },
    contentWrapper: {
      position: 'relative',
      zIndex: 1,
      padding: '40px 20px',
      breakInside: 'avoid-page',
    },
    newPageSection: {
      marginTop: 40,
    },
    pageNumber: {
      position: 'absolute',
      bottom: 30,
      right: 40,
      fontSize: 12,
      color: '#666666',
      padding: '8px 0',
      textAlign: 'right',
      zIndex: 100,
    },
    subSectionWithIcon: {
      marginLeft: 20,
      marginRight: 40,
      marginTop: 20,
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10,
    },
    sectionIcon: {
      width: 40,
      height: 40,
    },
    sectionTitleWithIcon: {
      fontSize: 14,
      color: '#9756D2',
      fontWeight: 'bold',
      marginTop: 15,
      marginBottom: 10,
      fontFamily: 'Helvetica-Bold',
      flex: 1,
    },
    // Table styles
    table: {
      margin: '20px 0',
      border: '1px solid #E0CFFF',
      borderRadius: 8,
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: '#F3F0FF',
      padding: 12,
      borderBottom: '1px solid #E0CFFF',
      position: 'relative',
    },
    tableHeaderCell: {
      color: '#9756D2',
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
    },
    taskColumn: {
      flex: 3,
      paddingRight: 20,
    },
    timelineColumn: {
      flex: 1,
      textAlign: 'center',
    },
    pointsColumn: {
      flex: 1,
      textAlign: 'center',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottom: '1px solid #E0CFFF',
      padding: '12px',
      position: 'relative',
    },
    tableTaskCell: {
      flex: 3,
      paddingRight: 20,
    },
    taskCell: {
      flex: 3,
      paddingRight: 20,
    },
    tableTimelineCell: {
      flex: 1,
      textAlign: 'center',
      color: '#666666',
    },
    pointsCell: {
      flex: 1,
      textAlign: 'center',
      color: '#666666',
    },
    tableSubSectionTitle: {
      fontSize: 14,
      color: '#9756D2',
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
      marginBottom: 8,
    },
    tableMainTask: {
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
      marginBottom: 8,
      color: '#9756D2',
    },
    tableSubTask: {
      fontSize: 12,
      marginLeft: 20,
      color: '#666666',
    },
    tableBulletText: {
      fontSize: 12,
      color: '#000000',
      marginLeft: 20,
      marginTop: 4,
    },
    tableBulletPoint: {
      fontSize: 12,
      color: '#000000',
      marginLeft: 20,
      marginTop: 4,
    },
    // Vertical line style
    verticalLine: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: '25%',
      width: 1,
      backgroundColor: '#E0CFFF',
    },
    socialLinksContainer: {
      marginTop: 40,
      marginBottom: 40,
    },
    socialLink: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 30,
    },
    socialIcon: {
      width: 40,
      height: 40,
      marginRight: 20,
    },
    socialText: {
      fontSize: 16,
      color: '#333333',
      textDecoration: 'underline',
    },
    // Single definition for footer logo container
    footerLogoContainer: {
      position: 'absolute',
      bottom: 40,
      right: 40,
      width: 200,
      height: 80,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    // Single definition for footer logo
    footerLogo: {
      width: '100%',
      objectFit: 'contain',
    },
    getInTouchContainer: {
      height: '100%',
      position: 'relative',
      padding: '40px 40px 120px 40px',
    },
    getInTouchTitle: {
      fontSize: 36,
      color: '#000000',
      marginBottom: 40,
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
    },
    getInTouchLink: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 30,
    },
    getInTouchIcon: {
      width: 40,
      height: 40,
      marginRight: 20,
    },
    getInTouchText: {
      fontSize: 16,
      color: '#333333',
      textDecoration: 'underline',
    },
    tocTable: {
      margin: '20px 0',
      border: '1px solid #E0CFFF',
      borderRadius: 8,
    },
    tocTableHeader: {
      flexDirection: 'row',
      backgroundColor: '#F3F0FF',
      padding: 12,
      borderBottom: '1px solid #E0CFFF',
      position: 'relative',
    },
    tocHeaderCell: {
      fontSize: 16,
      color: '#9756D2',
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
    },
    tocTableRow: {
      flexDirection: 'row',
      borderBottom: '1px solid #E0CFFF',
      padding: '12px',
      position: 'relative',
      minHeight: 40,  // Add fixed height to ensure consistent vertical space
    },
    tocPageNumber: {
      fontSize: 14,
      color: '#666666',
      textAlign: 'center',
      alignSelf: 'center',
      width: '20%',  // Fixed width for page numbers
      marginLeft: 'auto',  // Push to the right
      paddingRight: 20    // Add some padding from the right edge
    },
    tocVerticalLine: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: '80%',  // Changed from 25% to 85% to move line after No. column
      width: 1,
      backgroundColor: '#E0CFFF',
    },
    estimationContainer: {
      padding: '20px 40px',
    },
    estimationSection: {
      marginBottom: 20,
    },
    estimationFeature: {
      fontSize: 16,
      color: '#5631B4',
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
      marginBottom: 10,
    },
    estimationTotal: {
      fontSize: 14,
      color: '#666666',
      fontWeight: 'normal',
    },
    estimationRow: {
      marginLeft: 20,
      marginBottom: 5,
    },
    estimationSubItem: {
      fontSize: 14,
      color: '#000000',
      fontFamily: 'Helvetica',
    },
    estimationPoints: {
      color: '#666666',
    },
    reportDetails: {
      fontSize: 14,
      color: '#5631B4',
      textAlign: 'right',
      marginTop: 8,
      fontFamily: 'Helvetica',
      opacity: 0.8
    },
    detailsContainer: {
      position: 'absolute',
      bottom: 70,    // Changed from 40 to 70 to move up 30px
      right: 40,
      zIndex: 1,
      width: 300
    },
    tocContent: {
      flex: 1,
      paddingRight: 0,  // Add padding to keep text away from vertical line
    },
    tocNumberContainer: {
      flex: 1,
      height: '100%',  // Take full height of the row
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 16,
    },
  });

  private static readonly CONCLUSION_SECTION = `9. Conclusion and Next Steps

9.1. Conclusion
This Software Requirements Specification (SRS) document outlines a comprehensive overview of the project's objectives, functional and non-functional requirements, integration specifications, and development considerations. The goal is to ensure alignment between stakeholders and the development team, providing a solid foundation for a successful implementation.

The document serves as a reference point throughout the project lifecycle, ensuring clarity, transparency, and a shared understanding of the project's direction.

9.2. Next Steps

9.2.1. Stakeholder Review:
Review the document to confirm that all requirements align with business needs and expectations.

9.2.2. Feedback and Adjustments:
Schedule a review meeting to address any questions or updates. Revisions, if necessary, will be incorporated to reflect the latest feedback.

9.2.3. Approval and Sign-off:
Once finalized, obtain formal approval from all key stakeholders to proceed to the next phase of the project.

9.2.4. Implementation Roadmap:
Post sign-off, a detailed roadmap and project timeline will be created to guide development, testing, and deployment.

We look forward to collaborating closely to ensure the project's success. For any clarifications or to schedule a discussion, feel free to contact us at your convenience.`;

  private static PageHeader = () => (
    <View>
      <View style={this.styles.bottomTriangleContentNewPage} />
    </View>
  );

  private static contentHeightCalculator = {
    // Base heights in pixels with minimal buffers
    heights: {
      sectionTitle: 40,     // Just +2 from original
      subSection: 32,       // Just +2 from original
      subSubSection: 24,    // Just +2 from original
      regularText: 20,      // Just +2 from original
      tableRow: 40,         // Just +2 from original
      listItem: 24,         // Just +2 from original
      sectionSpacing: 20,   // Just +2 from original
      pageHeader: 60,       // Just +2 from original
      pageFooter: 40,       // Just +2 from original
      pageMargin: 40,       // Just +2 from original
      buffer: 10,           // Reduced from 20 to 10
    },

    // A4 page dimensions with minimal safety margin
    page: {
      totalHeight: 842,
      width: 595,
      safetyMargin: 20,    // Reduced from 40 to 20
      get usableHeight(): number {
        return this.totalHeight - 
          (SRSGenerator.contentHeightCalculator.heights.pageHeader +
           SRSGenerator.contentHeightCalculator.heights.pageFooter +
           SRSGenerator.contentHeightCalculator.heights.pageMargin * 2 +
           SRSGenerator.contentHeightCalculator.heights.buffer +
           this.safetyMargin);
      }
    },

    calculateContentHeight(content: string): number {
      let totalHeight = 0;
      const lines = content.split('\n').filter(line => line.trim());

      lines.forEach(line => {
        // Minimal buffer added to each calculation
        if (line.match(/^##?\s*\d+\./) ||                    // ## 1.
            line.match(/^##?\s*\d+\s/) ||                    // ## 1 
            line.match(/^##\s*\d+\.?\s*$/)) {                // ## 1.
          totalHeight += this.heights.sectionTitle + this.heights.sectionSpacing + (this.heights.buffer / 2);
        }
        else if (line.match(/^(-\s*)?\d+\.\d+\.?(\s|$)/) ||  // 1.1. or - 1.1.
                 line.match(/^\s*\d+\.\d+\.?(\s|$)/) ||      // 1.1 or spaces + 1.1
                 line.match(/^(-\s*)?\d+\.\d+[a-zA-Z]/)) {   // 1.1a or - 1.1a
          totalHeight += this.heights.subSection + this.heights.sectionSpacing + (this.heights.buffer / 2);
        }
        else if (line.match(/^(-\s*)?\d+\.\d+\.\d+\.?(\s|$)/) ||  // 1.1.1. or - 1.1.1.
                 line.match(/^\s*-?\s*\d+\.\d+\.\d+\.?(\s|$)/) || // spaces + 1.1.1 or spaces + - 1.1.1
                 line.match(/^(-\s*)?\d+\.\d+\.\d+[a-zA-Z]/)) {   // 1.1.1a or - 1.1.1a
          totalHeight += this.heights.subSubSection + this.heights.sectionSpacing + (this.heights.buffer / 2);
        }
        else if (line.match(/^[-•]\s/) ||                     // - or • with space
                 line.trim().startsWith('-') ||               // trimmed line starts with -
                 line.trim().startsWith('•') ||               // trimmed line starts with •
                 line.match(/^\s+[-•]/)) {                    // indented - or •
          totalHeight += this.heights.listItem + (this.heights.buffer / 2);
        }
        else {
          totalHeight += this.heights.regularText + (this.heights.buffer / 2);
        }
      });

      // Minimal extra padding
      totalHeight += this.heights.buffer;

      return totalHeight;
    },

    shouldStartNewPage(currentHeight: number, contentHeight: number): boolean {
      // Minimal buffer in check
      return (currentHeight + contentHeight + (this.heights.buffer / 2)) > this.page.usableHeight;
    },

    estimatePageCount(totalContentHeight: number): number {
      // No extra page, just ceil
      return Math.ceil(totalContentHeight / this.page.usableHeight);
    }
  };

  public static async generateSRSPDF(reportData: SRSData) {
    console.log('Starting PDF generation...');

    try {
    //   console.log('Starting content analysis...');
    //   const tocAnalysis = this.testContentCalculator(reportData.step1);
    //   console.log('Table of Contents Analysis:', tocAnalysis);

    //   const step2Analysis = this.testContentCalculator(reportData.step2);
    //   console.log('Step 2 Analysis:', step2Analysis);

      console.log('Creating SRS Document...');

      const SRSDocument = () => {
        console.log('Rendering document components...');
        
        const tocPages = this.formatTableOfContentsContent(reportData.step1);
        const step2Pages = this.formatStep2Content(reportData.step2);
        const step3Pages = this.formatStep3Content(reportData.step3);
        const step4Pages = this.formatStep4Content(reportData.step4);
        const step5Pages = this.formatStep5Content(reportData.step5);
        const step6Pages = this.formatStep6Content(reportData.step6);
        const step7Pages = this.formatStep7Content(reportData.step7);
        return (
          <Document>
            {/* Cover Page */}
            <Page size="A4" style={this.styles.coverPage}>
              {/* Background triangles */}
              <View style={this.styles.topTriangle} />
              <View style={this.styles.bottomTriangle} />
              
              {/* Centered Logo */}
              <View style={this.styles.logoContainer}>
                <Image 
                  style={this.styles.logo}
                  src={logoIcon}
                />
              </View>
              
              {/* Moved down illustration */}
              <View style={this.styles.illustrationContainer}>
                <Image 
                  style={this.styles.illustration}
                  src={teamImage}
                />
              </View>
              
              {/* Title */}
              <View style={this.styles.titleContainer}>
                <Text style={this.styles.softwareTitle}>
                  SOFTWARE REQUIREMENT SPECIFICATIONS
                </Text>
              </View>

              {/* Details at bottom left */}
              <View style={this.styles.detailsContainer}>
                <Text style={this.styles.reportDetails}>
                  Report Generated on: {new Date(reportData.timestamp).toLocaleDateString()}
                </Text>
                <Text style={this.styles.reportDetails}>
                  Name: {reportData.name}
                </Text>
                <Text style={this.styles.reportDetails}>
                  Email: {reportData.email}
                </Text>
              </View>

              {/* Remove or comment out page number */}
              {/* <Text 
                style={this.styles.pageNumber} 
                render={({ totalPages }) => `1/${totalPages}`} 
                fixed
              /> */}
            </Page>

            {/* Table of Contents */}
            {tocPages}

            {/* Section Break Page */}
            {/* <Page size="A4" style={this.styles.page}>
              <this.PageHeader />
              <View style={[this.styles.contentWrapper, { marginTop: 40 }]}>
                <Text style={[this.styles.sectionTitle, { textAlign: 'center' }]}>
                  Software Requirements Specification
                </Text>
              </View>
            </Page> */}

            {/* Step 2 Content */}
            {step2Pages}

            {/* Step 3 Content */}
            {step3Pages}

            {/* Step 4 Content */}
            {step4Pages}

            {/* Step 5 Content */}
            {step5Pages}

            {/* Step 6 Content */}
            {step6Pages}

            {/* Step 7 Content */}
            {step7Pages}  

            {/* Rest of the document */}
            {/* ... existing pages ... */}
          </Document>
        );
      };

      console.log('Returning document component...');
      return SRSDocument;

    } catch (error) {
      console.error('Error in generateSRSPDF:', error);
      throw error;
    }
  }

  private static formatTableOfContentsContent(tocData: string): React.ReactElement[] {
    // Append the new sections to tocData
    const updatedTocData = tocData + `
## 6. User Stories
## 7. UI/UX Design Plan
## 8. Test Cases`;

    // Use the updated string with existing processing
    const lines = updatedTocData
      .replace('# Table of Contents', '')
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0);

    // Organize content into hierarchical structure
    const sections: {
      main: string;
      subsections: {
        title: string;
        subsubsections: string[];
      }[];
    }[] = [];

    let currentMain = '';
    let currentSub = '';

    lines.forEach(line => {
      // Main section (starts with "## number.")
      if (line.startsWith('##')) {
        currentMain = line.replace('##', '').trim();
        sections.push({
          main: currentMain,
          subsections: []
        });
      }
      // Subsection (starts with number.number.)
      else if (line.match(/^(-\s*)?\d+\.\d+\.?(\s|$)/) || 
               line.match(/^(-\s*)?\d+\.\d+[a-zA-Z]/) ||
               line.match(/^\s*\d+\.\d+\.?(\s|$)/) ||
               line.match(/^\s*-\s*\d+\.\d+\.?(\s|$)/)) {
        // This will now match:
        // - 1.1 Project Overview
        // - 1.1. Project Overview
        // 1.1 Project Overview
        // 1.1. Project Overview
        // 1.1a Project Overview
        //   1.1 Project Overview (with leading spaces)
        //   - 1.1 Project Overview (with leading spaces)
        currentSub = line;
        if (sections.length > 0) {
          sections[sections.length - 1].subsections.push({
            title: currentSub,
            subsubsections: []
          });
        }
      }
      // Sub-subsection (starts with -)
      else if (line.match(/^-\s*\d+\.\d+\.\d+\.?(\s|$)/) ||
               line.match(/^-\s*\d+\.\d+\.\d+[a-zA-Z]/) ||
               line.match(/^\s*-\s*\d+\.\d+\.\d+\.?(\s|$)/) ||
               line.startsWith('-')) {
        // This will now match:
        // - 1.1.1 Purpose and Scope
        // - 1.1.1. Purpose and Scope
        // -1.1.1 Purpose and Scope
        // -1.1.1. Purpose and Scope
        //   - 1.1.1 Purpose and Scope (with leading spaces)
        // - 1.1.1a Purpose and Scope (with letter suffix)
        const subsubSection = line.replace('-', '').trim();
        if (sections.length > 0 && sections[sections.length - 1].subsections.length > 0) {
          sections[sections.length - 1].subsections[
            sections[sections.length - 1].subsections.length - 1
          ].subsubsections.push(subsubSection);
        }
      }
    });

    // Now split into pages based on height calculations
    const tocGroups: {
      main: string;
      subsections: {
        title: string;
        subsubsections: string[];
      }[];
    }[][] = [];
    let currentGroup: {
      main: string;
      subsections: {
        title: string;
        subsubsections: string[];
      }[];
    }[] = [];
    let currentHeight = 0;

    sections.forEach(section => {
      const sectionHeight = this.contentHeightCalculator.calculateContentHeight(
        [section.main, 
         ...section.subsections.map(sub => sub.title),
         ...section.subsections.flatMap(sub => sub.subsubsections)
        ].join('\n')
      );

      if (this.contentHeightCalculator.shouldStartNewPage(currentHeight, sectionHeight)) {
        if (currentGroup.length > 0) {
          tocGroups.push(currentGroup);
        }
        currentGroup = [];
        currentHeight = this.contentHeightCalculator.heights.pageMargin;
      }

      currentGroup.push(section);
      currentHeight += sectionHeight;
    });

    if (currentGroup.length > 0) {
      tocGroups.push(currentGroup);
    }

    // Render pages
    return tocGroups.map((group, pageIndex) => (
      <Page key={pageIndex} size="A4" style={this.styles.page}>
        <this.PageHeader />
        
        <View style={[
          this.styles.contentWrapper,
          pageIndex === 0 ? { marginTop: 0 } : { marginTop: 0 }
        ]}>
          {pageIndex === 0 && (
            <Text style={[
              this.styles.tocTitle,
              { marginBottom: 5 }
            ]}>
              Table of Contents
            </Text>
          )}
          
          <View style={this.styles.tocTable}>
            <View style={this.styles.tocTableHeader}>
              <Text style={[this.styles.tocHeaderCell, { flex: 1 }]}>No.</Text>
              <Text style={[this.styles.tocHeaderCell, { flex: 4, paddingLeft: 20 }]}>Section</Text>
              <View style={this.styles.tocVerticalLine} />
            </View>

            {group.map((section, sectionIndex) => (
              <View key={sectionIndex} style={this.styles.tocTableRow}>
                <View style={this.styles.tocNumberContainer}>
                  <Text style={[this.styles.tocContent, { 
                    fontFamily: 'Helvetica-Bold',
                  }]}>
                    {/* Extract just the number from the section title */}
                    {section.main.match(/^\d+/)?.[0] || 
                     (pageIndex === 0 ? sectionIndex + 1 : sectionIndex + 1 + (pageIndex * group.length))}
                  </Text>
                </View>
                <View style={{ flex: 4, paddingLeft: 20 }}>
                  <Text style={this.styles.tocMainSection}>{section.main}</Text>
                  {section.subsections.map((sub, subIndex) => (
                    <React.Fragment key={subIndex}>
                      <Text style={this.styles.tocSubSection}>{sub.title}</Text>
                      {sub.subsubsections.map((subsub, subsubIndex) => (
                        <Text key={subsubIndex} style={this.styles.tocSubSubSection}>
                          {subsub}
                        </Text>
                      ))}
                    </React.Fragment>
                  ))}
                </View>
                <View style={this.styles.tocVerticalLine} />
              </View>
            ))}
          </View>
        </View>

        {/* Remove or comment out page number */}
        {/* <Text style={this.styles.pageNumber}>
          {pageIndex + 1}
        </Text> */}
      </Page>
    ));
  }

  // Helper method to calculate page numbers (you'll need to implement this based on your content structure)
  private static calculatePageNumber(section: string): number {
    // This is a placeholder - implement actual page calculation logic
    // You might want to maintain a mapping of sections to their actual page numbers
    return 1;
  }

  // Helper method to format subsections
  private static formatTocSubsections(tocData: string, sectionNumber: string) {
    const lines = tocData.split('\n');
    const subsections = lines.filter(line => 
      line.trim().startsWith(`${sectionNumber}.`) && 
      !line.trim().startsWith(`${sectionNumber}. `)
    );

    return subsections.map((subsection, index) => (
      <Text key={index} style={this.styles.tocSubSection}>
        {subsection.trim()}
      </Text>
    ));
  }

  private static getIconForSection(sectionTitle: string): string {
    const title = sectionTitle.toLowerCase();
    
    if (title.includes('front') || title.includes('frontend') || title.includes('front-end') || title.includes('web') || title.includes('ui') || title.includes('ux') || title.includes('design') || title.includes('interface') || title.includes('user') || title.includes('web') || title.includes('ui') || title.includes('ux') || title.includes('design') || title.includes('interface') || title.includes('user') || title.includes('mobile')) {
      return frontendIcon;
    }
    if (title.includes('back') || title.includes('backend') || title.includes('back-end') || title.includes('server') || title.includes('api') || title.includes('rest') || title.includes('graphql') || title.includes('soap') || title.includes('rpc') || title.includes('microservice') || title.includes('service') || title.includes('api')) {
      return backendIcon;
    }
    if (title.includes('database') || title.includes('db') || title.includes('data') || title.includes('sql') || title.includes('nosql') || title.includes('orm') || title.includes('orms') || title.includes('database') || title.includes('db') || title.includes('data') || title.includes('sql') || title.includes('nosql') || title.includes('orm') || title.includes('orms') || title.includes('database') || title.includes('db') || title.includes('data') || title.includes('sql') || title.includes('nosql') || title.includes('orm') || title.includes('orms')) {
      return databaseIcon;
    }
    if (title.includes('version') || title.includes('git') || title.includes('control') || title.includes('scm') || title.includes('source') || title.includes('version') || title.includes('git') || title.includes('control') || title.includes('scm') || title.includes('source') || title.includes('version') || title.includes('git') || title.includes('control') || title.includes('scm') || title.includes('source') || title.includes('version') || title.includes('git') || title.includes('control') || title.includes('scm') || title.includes('source') || title.includes('version') || title.includes('git') || title.includes('control') || title.includes('scm') || title.includes('source')) {
      return versionIcon;
    }
    if (title.includes('test') || title.includes('qa') || title.includes('testing') || title.includes('unit') || title.includes('integration') || title.includes('functional') || title.includes('performance') || title.includes('security') || title.includes('load') || title.includes('stress') || title.includes('regression') || title.includes('acceptance') || title.includes('system') || title.includes('acceptance') || title.includes('user') || title.includes('test') || title.includes('qa') || title.includes('testing') || title.includes('unit') || title.includes('integration') || title.includes('functional') || title.includes('performance') || title.includes('security') || title.includes('load') || title.includes('stress') || title.includes('regression') || title.includes('acceptance') || title.includes('system') || title.includes('acceptance') || title.includes('user')) {
      return testingIcon;
    }
    if (title.includes('deploy') || title.includes('cloud') || title.includes('aws') || title.includes('azure') || title.includes('gcp') || title.includes('kubernetes') || title.includes('docker') || title.includes('container') || title.includes('serverless') || title.includes('devops') || title.includes('cicd') || title.includes('jenkins') || title.includes('gitlab') || title.includes('github') || title.includes('bitbucket') || title.includes('terraform') || title.includes('ansible') || title.includes('puppet') || title.includes('chef') || title.includes('salt') || title.includes('shell') || title.includes('scripting') || title.includes('automation') || title.includes('orchestration') || title.includes('monitoring') || title.includes('logging') || title.includes('alerting') || title.includes('backup') || title.includes('recovery') || title.includes('disaster') || title.includes('management') || title.includes('configuration') || title.includes('deployment') || title.includes('provisioning') || title.includes('provisioning') || title.includes('provisioning')) {
      return deploymentIcon;
    }
    
    return backendIcon;
  }

  // New method to split content into pages
  private static splitContentIntoPages(content: string): string[] {
    const pages: string[] = [];
    let currentPage: string[] = [];
    let currentHeight = 0;
    const usableHeight = this.contentHeightCalculator.page.usableHeight;

    console.log('Starting content split with usable height:', usableHeight);

    const lines = content.split('\n').filter(line => line.trim());
    
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const lineHeight = this.contentHeightCalculator.calculateContentHeight(line);
      
      console.log(`Processing line: "${line.substring(0, 30)}..."`, {
        lineHeight,
        currentHeight,
        usableHeight,
        wouldExceed: currentHeight + lineHeight > usableHeight
      });

      // Check if adding this line would exceed usable height
      if (currentHeight + lineHeight > usableHeight) {
        console.log('Creating new page', {
          currentPageLines: currentPage.length,
          totalPagesNow: pages.length
        });
        
        pages.push(currentPage.join('\n'));
        currentPage = [];
        currentHeight = 0;
      }

      // Special handling for main sections - start on new page if not first section
      if (this.isMainSectionHeader(line) && currentPage.length > 0) {
        console.log('Main section detected - starting new page');
        pages.push(currentPage.join('\n'));
        currentPage = [];
        currentHeight = 0;
      }

      currentPage.push(line);
      currentHeight += lineHeight;

      // Keep sub-sections with at least one content line if possible
      if (line.match(/^\d+\.\d+\.\s[A-Z]/) && i < lines.length - 1) {
        const nextLine = lines[i + 1];
        const nextLineHeight = this.contentHeightCalculator.calculateContentHeight(nextLine);
        
        if (currentHeight + nextLineHeight > usableHeight) {
          console.log('Sub-section would split from content - creating new page');
          pages.push(currentPage.join('\n'));
          currentPage = [];
          currentHeight = 0;
        }
      }
    }

    // Add remaining content
    if (currentPage.length > 0) {
      console.log('Adding final page', {
        remainingLines: currentPage.length
      });
      pages.push(currentPage.join('\n'));
    }

    console.log('Content split complete', {
      totalPages: pages.length,
      expectedPages: Math.ceil(this.contentHeightCalculator.calculateContentHeight(content) / usableHeight)
    });

    return pages;
  }

  private static estimateContentHeight(content: string): number {
    // Rough estimation of content height
    const lines = content.split('\n').length;
    const lineHeight = 20; // Approximate pixels per line
    return lines * lineHeight;
  }

  private static findParentSection(lines: string[], currentIndex: number): string | null {
    // Look backwards to find the parent section title
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (lines[i].match(/^\d+\.\d+(\.\d+)?/)) {
        return lines[i];
      }
    }
    return null;
  }

  private static splitTimelineAndEstimation(content: string) {
    const lowerContent = content.toLowerCase();
    const indices = {
      singular: lowerContent.indexOf('story point'),
      plural: lowerContent.indexOf('story points')
    };

    // If neither format is found
    if (indices.singular === -1 && indices.plural === -1) {
      return {
        timelineContent: content.trim(),
        estimationContent: ''
      };
    }

    // Find the first valid occurrence
    const splitIndex = [indices.singular, indices.plural]
      .filter(index => index !== -1)
      .sort((a, b) => a - b)[0];

    return {
      timelineContent: content.substring(0, splitIndex).trim(),
      estimationContent: content.substring(splitIndex).trim()
    };
  }

  private static parseTimelineContent(content: string) {
    const lines = content.split('\n').filter(l => l.trim());
    const timelineData: TimelineItem[] = [];
    let currentItem: TimelineItem | null = null;

    // Timeline-related keywords
    const timelineKeywords = [
      'duration',
      'target date',
      'date',
      'timeline',
      'estimation',
      'estimated time',
      'completion time',
      'delivery date',
      'deadline',
      'timeframe'
    ];

    const extractTimelineInfo = (text: string): { type: string, value: string } | null => {
      // Convert to lowercase for case-insensitive matching
      const lowerText = text.toLowerCase();
      
      // Find matching keyword
      const matchedKeyword = timelineKeywords.find(keyword => lowerText.includes(keyword));
      if (!matchedKeyword) return null;

      // Find the position of the keyword and colon
      const keywordIndex = lowerText.indexOf(matchedKeyword);
      const colonIndex = text.indexOf(':', keywordIndex);
      
      if (colonIndex === -1) return null;

      // Extract everything after the colon until the next punctuation or end
      const value = text.slice(colonIndex + 1)
        .split(/[.,;-]/) // Split on punctuation
        .shift()         // Take first part
        ?.trim();        // Clean up whitespace

      if (!value) return null;

      return {
        type: matchedKeyword,
        value: value
      };
    };

    for (const line of lines) {
      const cleanLine = line.replace(/^#+\s*/, '').trim();

      if (
        cleanLine.match(/^\d+\.\d+\.\d+\.?/) ||           // 1.1.1. or 1.1.1
        cleanLine.match(/^\d+\.\d+\.[A-Z]\.?/) ||         // 1.1.A. or 1.1.A
        cleanLine.match(/^[A-Z]\.\d+\.\d+\.?/) ||         // A.1.1. or A.1.1
        cleanLine.match(/^\d+\.[A-Z]\.\d+\.?/) ||         // 1.A.1. or 1.A.1
        cleanLine.match(/^-\s*\d+\.\d+\.\d+\.?/) ||       // - 1.1.1. or - 1.1.1
        cleanLine.match(/^-\d+\.\d+\.\d+\.?/) ||          // -1.1.1. or -1.1.1
        cleanLine.match(/^-\s*\d+\.\d+\.[A-Z]\.?/) ||     // - 1.1.A. or - 1.1.A
        cleanLine.match(/^-\d+\.\d+\.[A-Z]\.?/) ||        // -1.1.A. or -1.1.A
        cleanLine.match(/\[\d+\.\d+\.\d+\]\.?/) ||       // [1.1.1] or [1.1.1].
        cleanLine.match(/\(\d+\.\d+\.\d+\)\.?/) ||       // (1.1.1) or (1.1.1).
        cleanLine.match(/^Step\s+\d+\.\d+\.\d+\.?/) ||    // Step 1.1.1 or Step 1.1.1.
        cleanLine.match(/^Task\s+\d+\.\d+\.\d+\.?/)       // Task 1.1.1 or Task 1.1.1.
    ) {
        // Create new item for section header
        currentItem = { 
          title: cleanLine.split(/[-:]/)[0].trim(), // Take only the section title
          activities: [], 
          deliverables: [], 
          details: [] 
        };
        
        // Check for timeline info in the section header
        const timelineInfo = extractTimelineInfo(cleanLine);
        if (timelineInfo) {
          if (timelineInfo.type.includes('date') || timelineInfo.type.includes('deadline')) {
            currentItem.targetDate = timelineInfo.value;
          } else {
            currentItem.duration = timelineInfo.value;
          }
        }
        
        timelineData.push(currentItem);
      } else if (cleanLine.startsWith('-') || cleanLine.startsWith('•')) {
        if (!currentItem) continue;
        
        const bulletContent = cleanLine.replace(/^[-•]\s*/, '').trim();
        
        // Check for timeline info in bullet points
        const timelineInfo = extractTimelineInfo(bulletContent);
        if (timelineInfo) {
          if (timelineInfo.type.includes('date') || timelineInfo.type.includes('deadline')) {
            currentItem.targetDate = timelineInfo.value;
          } else {
            currentItem.duration = timelineInfo.value;
          }
          // Don't add this as a bullet point since it's timeline info
          continue;
        }

        // Regular bullet point content
        if (bulletContent.toLowerCase().includes('activities:')) {
          currentItem.activities?.push(bulletContent.replace(/^activities:\s*/i, ''));
        } else if (bulletContent.toLowerCase().includes('deliverables:')) {
          currentItem.deliverables?.push(bulletContent.replace(/^deliverables:\s*/i, ''));
        } else {
          currentItem.details?.push(bulletContent);
        }
      }
    }

    return timelineData;
  }

  private static parseEstimationContent(content: string) {
    const lines = content.split('\n').filter(l => l.trim());
    console.log('estimationData Calling Here...');

    const estimationData: EstimationItem[] = [];
    let currentFeature: EstimationItem | null = null;
    let summaryData: EstimationSummary | null = null;
    let totalFound = false; // Flag to track if total has been found

    // Enhanced patterns for story point detection
    const storyPointPatterns = [
      /-\s*([^:]+):\s*(\d+)\s*story\s*points?/i,               // - Feature: 20 story points
      /([^:]+):\s*(\d+)\s*story\s*points?/i,                   // Feature: 20 story points
      /\*\*([^*]+)\*\*:\s*(\d+)\s*story\s*points?/i,          // **Feature**: 30 story points
      /([^:]+)\s+-\s*(\d+)\s*story\s*points?/i,               // Feature - 25 story points
      /([^(]+)\s*\((\d+)\s*story\s*points?\)/i,               // Feature (20 story points)
      /([^:]+):\s*(\d+)\s*SP/i,                               // Feature: 20 SP
      /([^:]+):\s*(\d+)\s*pts/i,                              // Feature: 20 pts
      /([^:]+):\s*(\d+)\s*points/i,                           // Feature: 20 points
    ];

    // Enhanced feature header patterns
    const featurePatterns = [
      /^-?\s*\*\*([^*()]+(?:\([^)]+\))?)\*\*/,               // - **Frontend (Mobile and Web)**
      /^\d+\.\s+\*\*([^*()]+(?:\([^)]+\))?)\*\*/,            // 1. **Frontend (Mobile and Web)**
      /^[A-Z]\.\s+\*\*([^*()]+(?:\([^)]+\))?)\*\*/,          // A. **Frontend (Mobile and Web)**
      /^\*\*([^*()]+(?:\([^)]+\))?)\*\*/,                    // **Frontend (Mobile and Web)**
      /^#+\s*([^*()]+(?:\([^)]+\))?)/                        // ### Frontend (Mobile and Web)
    ];

    for (const line of lines) {
      console.log('Current line:', line);
      
      // Clean the line by removing markdown and special characters
      const cleanLine = line
        .replace(/^#+\s*/, '')         // Remove markdown headers
        .replace(/\*\*/g, '')          // Remove bold markers
        .replace(/`/g, '')             // Remove code ticks
        .replace(/\[|\]/g, '')         // Remove square brackets
        .replace(/\(|\)/g, '')         // Remove parentheses
        .replace(/_{1,2}|~~/g, '')     // Remove underscores and strikethrough
        .replace(/\|/g, '')            // Remove vertical bars
        .replace(/>/g, '')             // Remove blockquote markers
        .replace(/\s+/g, ' ')          // Normalize whitespace
        .trim();                       // Remove extra whitespace
      
      console.log('Cleaned line:', cleanLine);
      
      // Check for total estimation using simple text match
      if (
        cleanLine.startsWith('Total Story Points:') || 
        cleanLine.startsWith('Total Estimated Story Points:')
      ) {
        const points = cleanLine.match(/:\s*(\d+)/);
        if (points && points[1]) {
          console.log('Found total points:', points[1]);
          
          totalFound = true;
          summaryData = {
            total: parseInt(points[1], 10),
            breakdowns: []
          };
          break;
        }
      }

      // Only process features and story points if total hasn't been found yet
      if (!totalFound) {
        // Check for feature headers
        let featureMatch: RegExpMatchArray | null = null;
        for (const pattern of featurePatterns) {
          const match = line.match(pattern);
          if (match) {
            featureMatch = match;
            break;
          }
        }

        if (featureMatch) {
          currentFeature = {
            feature: featureMatch[1].trim(),
            subItems: []
          };
          estimationData.push(currentFeature);
          continue;
        }

        // Check for story points in the line
        if (currentFeature && line.trim().startsWith('-')) {
          for (const pattern of storyPointPatterns) {
            const match = line.match(pattern);
            if (match) {
              currentFeature.subItems.push({
                name: match[1].trim().replace(/^[-•]\s*/, ''),
                points: parseInt(match[2], 10)
              });
              break;
            }
          }
        }
      }
    }

    if (estimationData.length === 0 && !summaryData) {
      return null;
    }

    console.log('Parsed Estimation Data:', { estimationData, summaryData });
    return {
      features: estimationData,
      summary: summaryData
    };
  }

  private static renderTimelineTable(data: TimelineItem[]) {
    return (
      <View style={this.styles.table}>
        <View style={this.styles.tableHeader}>
          <Text style={[this.styles.tableHeaderCell, this.styles.taskColumn]}>Task</Text>
          <Text style={[this.styles.tableHeaderCell, this.styles.timelineColumn]}>Timeline</Text>
          <View style={this.styles.verticalLine} />
        </View>

        {data.map((item, index) => (
          <View key={index} style={this.styles.tableRow}>
            <View style={this.styles.tableTaskCell}>
              <Text style={this.styles.tableSubSectionTitle}>{item.title}</Text>
              {item.activities?.map((activity, i) => (
                <Text key={`activity-${i}`} style={this.styles.tableBulletText}>
                  • {activity}
                </Text>
              ))}
              {item.deliverables?.map((deliverable, i) => (
                <Text key={`deliverable-${i}`} style={this.styles.tableBulletText}>
                  • {deliverable}
                </Text>
              ))}
              {item.details?.map((detail, i) => (
                <Text key={`detail-${i}`} style={this.styles.tableBulletText}>
                  • {detail}
                </Text>
              ))}
            </View>
            <Text style={this.styles.tableTimelineCell}>
              {item.duration ? `${item.duration}` : item.targetDate ? `${item.targetDate}` : 'To Be Determined'}
            </Text>
            <View style={this.styles.verticalLine} />
          </View>
        ))}
      </View>
    );
  }

  private static renderEstimationTable(data: { features: EstimationItem[], summary: EstimationSummary | null }) {
    return (
      <View>
        <View style={this.styles.table}>
          <View style={this.styles.tableHeader}>
            <Text style={[this.styles.tableHeaderCell, this.styles.taskColumn]}>Feature</Text>
            <Text style={[this.styles.tableHeaderCell, this.styles.pointsColumn]}>Story Points</Text>
            <View style={this.styles.verticalLine} />
          </View>
          {data.features.map((item, index) => (
            <React.Fragment key={index}>
              <View style={this.styles.tableRow}>
                <View style={this.styles.taskCell}>
                  <Text 
                    style={[
                      this.styles.tableMainTask,
                      item.feature.includes('(continued)') ? { fontSize: 13 } : {}
                    ]}
                  >
                    {item.feature}
                  </Text>
                </View>
                <Text style={this.styles.pointsCell}>
                  ({item.subItems.reduce((sum, sub) => sum + sub.points, 0)})
                </Text>
              </View>
              {item.subItems.map((subItem, subIndex) => (
                <View key={`${index}-${subIndex}`} style={this.styles.tableRow}>
                  <View style={this.styles.taskCell}>
                    <Text style={this.styles.tableSubTask}>• {subItem.name}</Text>
                  </View>
                  <Text style={this.styles.pointsCell}>{subItem.points}</Text>
                </View>
              ))}
            </React.Fragment>
          ))}
        </View>

        {data.summary && (
          <View style={[this.styles.table, { marginTop: 20 }]}>
            <View style={this.styles.tableHeader}>
              <Text style={[this.styles.tableHeaderCell, this.styles.taskColumn]}>Total Estimation Breakdown</Text>
              <Text style={[this.styles.tableHeaderCell, this.styles.pointsColumn]}>Points</Text>
              <View style={this.styles.verticalLine} />
            </View>
            {data.summary.breakdowns.map((breakdown, index) => (
              <View key={index} style={this.styles.tableRow}>
                <View style={this.styles.taskCell}>
                  <Text style={this.styles.tableMainTask}>{breakdown.category}</Text>
                </View>
                <Text style={this.styles.pointsCell}>{breakdown.points}</Text>
              </View>
            ))}
            <View style={[this.styles.tableRow, { backgroundColor: '#F3F0FF' }]}>
              <View style={this.styles.taskCell}>
                <Text style={[this.styles.tableMainTask, { color: '#5631B4' }]}>Total Story Points</Text>
              </View>
              <Text style={[this.styles.pointsCell, { color: '#5631B4', fontWeight: 'bold' }]}>
                {data.summary.total}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  }

  private static formatTimelineAndEstimation(content: string) {
    const { timelineContent, estimationContent } = this.splitTimelineAndEstimation(content);
    const timelineData = this.parseTimelineContent(timelineContent);
    const estimationData = estimationContent ? this.parseEstimationContent(estimationContent) : null;

    return (
      <>
        <Page size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            <Text style={this.styles.sectionTitle}>5. Project Timeline and Milestones</Text>
            {this.renderTimelineTable(timelineData)}
          </View>
        </Page>

        {estimationData && estimationData.features.length > 0 && (
          <Page size="A4" style={this.styles.page}>
            <this.PageHeader />
            <View style={this.styles.contentWrapper}>
              <Text style={this.styles.sectionTitle}>Story Points Estimation</Text>
              {this.renderFeatureEstimationTable(estimationData.features)}
            </View>
          </Page>
        )}

        {estimationData?.summary && Object.keys(estimationData.summary).length > 0 && (
          <Page size="A4" style={this.styles.page}>
            <this.PageHeader />
            <View style={this.styles.contentWrapper}>
              <Text style={this.styles.sectionTitle}>Total Estimation Breakdown</Text>
              {this.renderEstimationSummaryTable(estimationData.summary)}
            </View>
          </Page>
        )}

        <Page size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            {this.formatDetailedContent(this.CONCLUSION_SECTION)}
          </View>
          {/* Remove or comment out page number */}
          {/* <Text style={this.styles.pageNumber}>
            {1}
          </Text> */}
        </Page>

        <Page size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={[this.styles.contentWrapper, { 
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 40  // Add some padding to match TOC style
          }]}>
            <Text style={[this.styles.tocTitle, { textAlign: 'center' }]}>
              Thank You
            </Text>
          </View>

          {/* Bottom Right Logo - keeping existing logo */}
          <View style={[this.styles.footerLogoContainer, {
            position: 'absolute',
            bottom: 40,
            right: 40,
            width: 100,
            height: 40
          }]}>
            <Image src={logoIcon} style={this.styles.footerLogo} />
          </View>
        </Page>
      </>
    );
  }

  private static renderFeatureEstimationTable(features: EstimationItem[]) {
    return (
      <View style={this.styles.table}>
        <View style={this.styles.tableHeader}>
          <Text style={[this.styles.tableHeaderCell, this.styles.taskColumn]}>Features and Breakdown</Text>
          <Text style={[this.styles.tableHeaderCell, this.styles.pointsColumn]}>Total Points</Text>
          <View style={this.styles.verticalLine} />
        </View>
        {features.map((item, index) => (
          <View key={index} style={this.styles.tableRow}>
            <View style={this.styles.taskCell}>
              <Text style={this.styles.tableMainTask}>{item.feature}</Text>
              {item.subItems.map((subItem, subIndex) => (
                <Text key={`${index}-${subIndex}`} style={this.styles.tableBulletText}>
                  • {subItem.name}: {subItem.points > 0 ? `${subItem.points} points` : '-'}
                </Text>
              ))}
            </View>
            <Text style={this.styles.pointsCell}>
              {item.subItems.reduce((sum, sub) => sum + sub.points, 0) || '-'}
            </Text>
            <View style={this.styles.verticalLine} />
          </View>
        ))}
      </View>
    );
  }

  private static renderEstimationSummaryTable(summary: EstimationSummary) {
    return (
      <View style={this.styles.table}>
        <View style={this.styles.tableHeader}>
          <Text style={[this.styles.tableHeaderCell, this.styles.taskColumn]}>Category</Text>
          <Text style={[this.styles.tableHeaderCell, this.styles.pointsColumn]}>Story Points</Text>
          <View style={this.styles.verticalLine} />
        </View>
        {summary.breakdowns.map((breakdown, index) => (
          <View key={index} style={this.styles.tableRow}>
            <View style={this.styles.taskCell}>
              <Text style={this.styles.tableMainTask}>{breakdown.category}</Text>
            </View>
            <Text style={this.styles.pointsCell}>{breakdown.points}</Text>
          </View>
        ))}
        <View style={[this.styles.tableRow, { backgroundColor: '#F3F0FF' }]}>
          <View style={this.styles.taskCell}>
            <Text style={[this.styles.tableMainTask, { color: '#5631B4' }]}>Total Story Points</Text>
          </View>
          <Text style={[this.styles.pointsCell, { color: '#5631B4', fontWeight: 'bold' }]}>
            {summary.total}
          </Text>
        </View>
      </View>
    );
  }

  private static testContentCalculator(content: string) {
    // Calculate total content height
    const totalHeight = this.contentHeightCalculator.calculateContentHeight(content);
    
    // Calculate estimated pages
    const estimatedPages = this.contentHeightCalculator.estimatePageCount(totalHeight);
    
    // Calculate usable height per page
    const usableHeight = this.contentHeightCalculator.page.usableHeight;

    console.log('Content Analysis:', {
      totalContentHeight: totalHeight,
      usableHeightPerPage: usableHeight,
      estimatedPages: estimatedPages,
      contentBreakdown: {
        sectionTitleHeight: this.contentHeightCalculator.heights.sectionTitle,
        subSectionHeight: this.contentHeightCalculator.heights.subSection,
        regularTextHeight: this.contentHeightCalculator.heights.regularText,
      }
    });

    // Test content splitting
    const lines = content.split('\n').filter(line => line.trim());
    let currentPageHeight = 0;
    let pageNumber = 1;

    lines.forEach((line, index) => {
      const lineHeight = this.contentHeightCalculator.calculateContentHeight(line);
      
      if (this.contentHeightCalculator.shouldStartNewPage(currentPageHeight, lineHeight)) {
        console.log(`Page Break after line ${index}: "${line.substring(0, 50)}..."`);
        currentPageHeight = 0;
        pageNumber++;
      }
      
      currentPageHeight += lineHeight;
    });

    return {
      totalPages: pageNumber,
      totalHeight,
      usableHeight,
      estimatedPages
    };
  }

  private static formatStep2Content(step2Data: string): React.ReactElement[] {
    const pages: React.ReactElement[] = [];
    const lines = step2Data.split('\n').filter(line => line.trim());
    
    let currentPageLines: string[] = [];
    let currentHeight = 0;
    const usableHeight = this.contentHeightCalculator.page.usableHeight;

    console.log('Starting Step 2 formatting with usable height:', usableHeight);

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const lineHeight = this.contentHeightCalculator.calculateContentHeight(line);

      // Check if we need to start a new page
      if (currentHeight + lineHeight > usableHeight || 
          (this.isMainSectionHeader(line) && currentPageLines.length > 0)) {
            
        // Create new page with current content
        pages.push(
          <Page key={`step2-page-${pages.length}`} size="A4" style={this.styles.page}>
            <this.PageHeader />
            <View style={this.styles.contentWrapper}>
              {this.formatDetailedContent(currentPageLines.join('\n'))}
            </View>
            {/* Remove or comment out page number */}
            {/* <Text style={this.styles.pageNumber}>
              {pages.length + 1}
            </Text> */}
          </Page>
        );

        // Reset for next page
        currentPageLines = [];
        currentHeight = 0;
      }

      currentPageLines.push(line);
      currentHeight += lineHeight;

      console.log(`Added line to page ${pages.length + 1}:`, {
        linePreview: line.substring(0, 30),
        currentHeight,
        usableHeight,
        linesInCurrentPage: currentPageLines.length
      });
    }

    // Add remaining content as final page
    if (currentPageLines.length > 0) {
      pages.push(
        <Page key={`step2-page-${pages.length}`} size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            {this.formatDetailedContent(currentPageLines.join('\n'))}
          </View>
          {/* Remove or comment out page number */}
          {/* <Text style={this.styles.pageNumber}>
            {pages.length + 1}
          </Text> */}
        </Page>
      );
    }

    console.log('Step 2 formatting complete', {
      totalPages: pages.length,
      expectedPages: Math.ceil(this.contentHeightCalculator.calculateContentHeight(step2Data) / usableHeight)
    });

    return pages;
  }

  private static formatDetailedContent(contentData: string) {
    const lines = contentData
      .split('\n')
      .filter(line => line.trim())
      .map(line => line.replace(/^#+\s*/, '').trim());
  
    console.log('Processing content lines:', lines);  // Add this log
    
    return (
      <View style={{ position: 'relative', zIndex: 1 }}>
        {lines.map((line, index) => {
          // Main section (4. Development Environment)
          if (this.isMainSectionHeader(line) && this.isSpecificMainSection(line)) {
            return (
              <Text key={index} style={this.styles.sectionTitle}>
                {line}
              </Text>
            );
          }
          
          // Sub section - keep original style for all
          else if (
            line.match(/^\d+\.\d+\.?\s+[A-Z]/) ||     // 1.1. Title or 1.1 Title
            line.match(/^[A-Z]\.\s+[A-Z]/) ||         // A. Title
            line.match(/^\d+\.[A-Z]\.?\s+[A-Z]/) ||   // 1.A. Title or 1.A Title
            line.match(/^#{1,3}\s*\d+\.\d+\.?\s+[A-Z]/) || // ### 1.1. Title
            line.match(/^[7-9]\.[2-3]\.\s+[A-Z]/)     // Specifically for 7.2. and 7.3. sections
          ) {
            console.log('Subsection text content:', {
              line,
              length: line.length,
              isEmpty: !line,
              type: typeof line
            });
            return (
              <View key={index} style={this.styles.subSectionContainer}>
                <View style={this.styles.subSectionBackground}>
                  <Text style={this.styles.subSectionTitle}>
                    {line}
                  </Text>
                </View>
              </View>
            );
          }
          
          // Sub-sub section - special icon style for section 4
          else if (
            line.match(/^\d+\.\d+\.\d+\.?\s+[A-Z]/) ||      // 1.1.1. Title or 1.1.1 Title
            line.match(/^\d+\.\d+\.[A-Z]\.?\s+[A-Z]/) ||    // 1.1.A. Title or 1.1.A Title
            line.match(/^[A-Z]\.\d+\.\d+\.?\s+[A-Z]/) ||    // A.1.1. Title
            line.match(/^\d+\.[A-Z]\.\d+\.?\s+[A-Z]/) ||    // 1.A.1. Title
            line.match(/^\d+\.\d+\.[ivxIVX]+\.?\s+[A-Z]/) || // 1.1.i. Title or 1.1.IV. Title
            line.match(/^\d+\.[ivxIVX]+\.\d+\.?\s+[A-Z]/) || // 1.i.1. Title or 1.IV.1. Title
            line.match(/^\d+\.\d+\.\d+[a-z]\.?\s+[A-Z]/) ||  // 1.1.1a. Title or 1.1.1b Title
            line.match(/^\d+\.\d+\.\d+\s*\([a-z]\)\.?\s+[A-Z]/) || // 1.1.1(a). Title
            line.match(/^\d+\.\d+\.\d+\s*\([ivxIVX]+\)\.?\s+[A-Z]/) || // 1.1.1(i). Title
            line.match(/^#{1,4}\s*\d+\.\d+\.\d+\.?\s+[A-Z]/) // ### 1.1.1. Title
          ) {
            const sectionNumber = line.split('.')[0];
            
            if (sectionNumber === '4') {
              const icon = this.getIconForSection(line);
              return (
                <View key={index} style={this.styles.subSectionWithIcon}>
                  <Image 
                    src={icon}
                    style={this.styles.sectionIcon}
                  />
                  <Text style={this.styles.sectionTitleWithIcon}>
                    {line}
                  </Text>
                </View>
              );
            } else {
              return (
                <Text key={index} style={this.styles.subSubSectionTitle}>
                  {line}
                </Text>
              );
            }
          }
          
          // Regular content and bullet points
          else if (line.startsWith('•') || line.startsWith('-')) {
            const text = line.replace(/^[•-]\s*/, '').trim();
            const parts = text.split(/(\*\*.*?\*\*)/g);
            
            return (
              <Text key={index} style={this.styles.bulletPoint}>
                • {parts.map((part, pIndex) => {
                  if (part.startsWith('**') && part.endsWith('**')) {
                    return (
                      <Text key={pIndex} style={{ fontFamily: 'Helvetica-Bold' }}>
                        {part.slice(2, -2)}
                      </Text>
                    );
                  }
                  return part;
                })}
              </Text>
            );
          }
          else {
            return (
              <Text key={index} style={this.styles.contentText}>
                {line}
              </Text>
            );
          }
        })}
      </View>
    );
  }

  private static formatStep3Content(step3Data: string): React.ReactElement[] {
    // Split content into Section 3 and Section 4
    const sections = this.splitStep3Sections(step3Data);
    const pages: React.ReactElement[] = [];
    
    // Process Section 3
    if (sections.section3) {
      const section3Pages = this.splitContentIntoPages(sections.section3);
      pages.push(...section3Pages.map((pageContent, index) => (
        <Page key={`step3-section3-${index}`} size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            {this.formatDetailedContent(pageContent)}
          </View>
          {/* Remove or comment out page number */}
          {/* <Text style={this.styles.pageNumber}>
            {pages.length + 1}
          </Text> */}
        </Page>
      )));
    }

    // Process Section 4
    if (sections.section4) {
      const section4Pages = this.splitContentIntoPages(sections.section4);
      pages.push(...section4Pages.map((pageContent, index) => (
        <Page key={`step3-section4-${index}`} size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            {this.formatDetailedContent(pageContent)}
          </View>
          {/* Remove or comment out page number */}
          {/* <Text style={this.styles.pageNumber}>
            {pages.length + 1}
          </Text> */}
        </Page>
      )));
    }

    return pages;
  }

  private static splitStep3Sections(content: string): { section3: string; section4: string } {
    const lines = content.split('\n');
    let section3Lines: string[] = [];
    let section4Lines: string[] = [];
    let currentSection: '3' | '4' | null = null;

    for (const line of lines) {
      // Check for main section headers
      if (line.match(/^##?\s*[34]\./)) {
        currentSection = line.includes('3.') ? '3' : '4';
      }

      // Add line to appropriate section
      if (currentSection === '3') {
        section3Lines.push(line);
      } else if (currentSection === '4') {
        section4Lines.push(line);
      }
    }

    return {
      section3: section3Lines.join('\n'),
      section4: section4Lines.join('\n')
    };
  }

  private static formatStep4Content(step4Data: string): React.ReactElement[] {
    const { timelineContent, estimationContent } = this.splitTimelineAndEstimation(step4Data);
    const pages: React.ReactElement[] = [];
    
    // Process Timeline section
    const timelineData = this.parseTimelineContent(timelineContent);
    const timelineTableHeight = this.calculateTableHeight(timelineData);
    const timelinePagesNeeded = Math.ceil(timelineTableHeight / this.contentHeightCalculator.page.usableHeight);
    
    // Split timeline data into pages
    for (let i = 0; i < timelinePagesNeeded; i++) {
      const startIndex = Math.floor(timelineData.length * (i / timelinePagesNeeded));
      const endIndex = Math.floor(timelineData.length * ((i + 1) / timelinePagesNeeded));
      const pageData = timelineData.slice(startIndex, endIndex);
      
      pages.push(
        <Page key={`timeline-${i}`} size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            {i === 0 && (
              <Text style={this.styles.sectionTitle}>5. Project Timeline and Milestones</Text>
            )}
            {this.renderTimelineTable(pageData)}
          </View>
          {/* Remove or comment out page number */}
          {/* <Text style={this.styles.pageNumber}>
            {pages.length + 1}
          </Text> */}
        </Page>
      );
    }

    // Process Estimation section with improved height calculation
    if (estimationContent) {
      const estimationData = this.parseEstimationContent(estimationContent);
      if (estimationData) {
        const featureGroups = this.splitEstimationIntoPages(estimationData.features);
        
        // Render feature estimation pages
        featureGroups.forEach((features, index) => {
          pages.push(
            <Page key={`estimation-features-${index}`} size="A4" style={this.styles.page}>
              <this.PageHeader />
              <View style={this.styles.contentWrapper}>
                {index === 0 && (
                  <Text style={this.styles.sectionTitle}>Story Points Estimation</Text>
                )}
                {this.renderFeatureEstimationTable(features)}
              </View>
              {/* Remove or comment out page number */}
              {/* <Text style={this.styles.pageNumber}>
                {pages.length + 1}
              </Text> */}
            </Page>
          );
        });

        // Render summary page (keeping existing code)...
      }
    }

    // Add Conclusion and Next Steps page using the static content
    // pages.push(
    //   <Page size="A4" style={this.styles.page}>
    //     <this.PageHeader />
    //     <View style={this.styles.contentWrapper}>
    //       {this.formatDetailedContent(this.CONCLUSION_SECTION)}
    //     </View>
    //     {/* Remove or comment out page number */}
    //     {/* <Text style={this.styles.pageNumber}>
    //       {pages.length + 1}
    //     </Text> */}
    //   </Page>
    // );

    // Add Get in Touch page
    // pages.push(
    //   <Page size="A4" style={this.styles.page}>
    //     <this.PageHeader />
    //     <View style={[this.styles.contentWrapper, { 
    //       flex: 1,
    //       justifyContent: 'center',
    //       alignItems: 'center'
    //     }]}>
    //       {/* Center Thank You Message */}
    //       <Text style={this.styles.tocTitle}>
    //         Thank You
    //       </Text>
    //     </View>

    //     {/* Bottom Right Logo - keeping existing logo */}
    //     <View style={[this.styles.footerLogoContainer, {
    //       position: 'absolute',
    //       bottom: 40,
    //       right: 40,
    //       width: 100,
    //       height: 40
    //     }]}>
    //       <Image src={logoIcon} style={this.styles.footerLogo} />
    //     </View>
    //   </Page>
    // );

    return pages;
  }

  private static formatStep5Content(step5Data: string): React.ReactElement[] {
    const pages: React.ReactElement[] = [];
    const lines = step5Data.split('\n').filter(line => line.trim());
    
    let currentPageLines: string[] = [];
    let currentHeight = 0;
    const usableHeight = this.contentHeightCalculator.page.usableHeight;

    console.log('Starting Step 5 formatting with usable height:', usableHeight);

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const lineHeight = this.contentHeightCalculator.calculateContentHeight(line);

      // Check if we need to start a new page
      if (currentHeight + lineHeight > usableHeight || 
          (this.isMainSectionHeader(line) && currentPageLines.length > 0)) {
            
        // Create new page with current content
        pages.push(
          <Page key={`step5-page-${pages.length}`} size="A4" style={this.styles.page}>
            <this.PageHeader />
            <View style={this.styles.contentWrapper}>
              {this.formatDetailedContent(currentPageLines.join('\n'))}
            </View>
            {/* Remove or comment out page number */}
            {/* <Text style={this.styles.pageNumber}>
              {pages.length + 1}
            </Text> */}
          </Page>
        );

        // Reset for next page
        currentPageLines = [];
        currentHeight = 0;
      }

      currentPageLines.push(line);
      currentHeight += lineHeight;

      console.log(`Added line to page ${pages.length + 1}:`, {
        linePreview: line.substring(0, 30),
        currentHeight,
        usableHeight,
        linesInCurrentPage: currentPageLines.length
      });
    }

    // Add remaining content as final page
    if (currentPageLines.length > 0) {
      pages.push(
        <Page key={`step5-page-${pages.length}`} size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            {this.formatDetailedContent(currentPageLines.join('\n'))}
          </View>
          {/* Remove or comment out page number */}
          {/* <Text style={this.styles.pageNumber}>
            {pages.length + 1}
          </Text> */}
        </Page>
      );
    }

    console.log('Step 5 formatting complete', {
      totalPages: pages.length,
      expectedPages: Math.ceil(this.contentHeightCalculator.calculateContentHeight(step5Data) / usableHeight)
    });

    return pages;
  }

  private static formatStep6Content(step6Data: string): React.ReactElement[] {
    const pages: React.ReactElement[] = [];
    const lines = step6Data.split('\n').filter(line => line.trim());
    
    let currentPageLines: string[] = [];
    let currentHeight = 0;
    const usableHeight = this.contentHeightCalculator.page.usableHeight;

    console.log('Starting Step 6 formatting with usable height:', usableHeight);

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const lineHeight = this.contentHeightCalculator.calculateContentHeight(line);

      // Check if we need to start a new page
      if (currentHeight + lineHeight > usableHeight || 
          (this.isMainSectionHeader(line) && currentPageLines.length > 0)) {
            
        // Create new page with current content
        pages.push(
          <Page key={`step6-page-${pages.length}`} size="A4" style={this.styles.page}>
            <this.PageHeader />
            <View style={this.styles.contentWrapper}>
              {this.formatDetailedContent(currentPageLines.join('\n'))}
            </View>
            {/* Remove or comment out page number */}
            {/* <Text style={this.styles.pageNumber}>
              {pages.length + 1}
            </Text> */}
          </Page>
        );

        // Reset for next page
        currentPageLines = [];
        currentHeight = 0;
      }

      currentPageLines.push(line);
      currentHeight += lineHeight;

      console.log(`Added line to page ${pages.length + 1}:`, {
        linePreview: line.substring(0, 30),
        currentHeight,
        usableHeight,
        linesInCurrentPage: currentPageLines.length
      });
    }

    // Add remaining content as final page
    if (currentPageLines.length > 0) {
      pages.push(
        <Page key={`step6-page-${pages.length}`} size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            {this.formatDetailedContent(currentPageLines.join('\n'))}
          </View>
          {/* Remove or comment out page number */}
          {/* <Text style={this.styles.pageNumber}>
            {pages.length + 1}
          </Text> */}
        </Page>
      );
    }

    console.log('Step 6 formatting complete', {
      totalPages: pages.length,
      expectedPages: Math.ceil(this.contentHeightCalculator.calculateContentHeight(step6Data) / usableHeight)
    });

    return pages;
  }

  private static formatStep7Content(step7Data: string): React.ReactElement[] {
    const pages: React.ReactElement[] = [];
    const lines = step7Data.split('\n').filter(line => line.trim());
    
    let currentPageLines: string[] = [];
    let currentHeight = 0;
    const usableHeight = this.contentHeightCalculator.page.usableHeight;

    console.log('Starting Step 7 formatting with usable height:', usableHeight);

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const lineHeight = this.contentHeightCalculator.calculateContentHeight(line);

      // Check if we need to start a new page
      if (currentHeight + lineHeight > usableHeight || 
          (this.isMainSectionHeader(line) && currentPageLines.length > 0)) {
            
        // Create new page with current content
        pages.push(
          <Page key={`step7-page-${pages.length}`} size="A4" style={this.styles.page}>
            <this.PageHeader />
            <View style={this.styles.contentWrapper}>
              {this.formatDetailedContent(currentPageLines.join('\n'))}
            </View>
            {/* Remove or comment out page number */}
            {/* <Text style={this.styles.pageNumber}>
              {pages.length + 1}
            </Text> */}
          </Page>
        );

        // Reset for next page
        currentPageLines = [];
        currentHeight = 0;
      }

      currentPageLines.push(line);
      currentHeight += lineHeight;

      console.log(`Added line to page ${pages.length + 1}:`, {
        linePreview: line.substring(0, 30),
        currentHeight,
        usableHeight,
        linesInCurrentPage: currentPageLines.length
      });
    }

    // Add remaining content as final page
    if (currentPageLines.length > 0) {
      pages.push(
        <Page key={`step7-page-${pages.length}`} size="A4" style={this.styles.page}>
          <this.PageHeader />
          <View style={this.styles.contentWrapper}>
            {this.formatDetailedContent(currentPageLines.join('\n'))}
          </View>
          {/* Remove or comment out page number */}
          {/* <Text style={this.styles.pageNumber}>
            {pages.length + 1}
          </Text> */}
        </Page>
      );
    }

          // Add Conclusion and Next Steps page using the static content
          pages.push(
            <Page size="A4" style={this.styles.page}>
              <this.PageHeader />
              <View style={this.styles.contentWrapper}>
                {this.formatDetailedContent(this.CONCLUSION_SECTION)}
              </View>
              {/* Remove or comment out page number */}
              {/* <Text style={this.styles.pageNumber}>
                {pages.length + 1}
              </Text> */}
            </Page>
          );
      
          // Add Get in Touch page
          pages.push(
            <Page size="A4" style={this.styles.page}>
              <this.PageHeader />
              <View style={[this.styles.contentWrapper, { 
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center'
              }]}>
                {/* Center Thank You Message */}
                <Text style={this.styles.tocTitle}>
                  Thank You
                </Text>
              </View>
      
              {/* Bottom Right Logo - keeping existing logo */}
              <View style={[this.styles.footerLogoContainer, {
                position: 'absolute',
                bottom: 40,
                right: 40,
                width: 100,
                height: 40
              }]}>
                <Image src={logoIcon} style={this.styles.footerLogo} />
              </View>
            </Page>
          );

    console.log('Step 7 formatting complete', {
      totalPages: pages.length,
      expectedPages: Math.ceil(this.contentHeightCalculator.calculateContentHeight(step7Data) / usableHeight)
    });


    return pages;
  }

  private static splitEstimationIntoPages(features: EstimationItem[]): EstimationItem[][] {
    const pages: EstimationItem[][] = [];
    let currentPage: EstimationItem[] = [];
    let currentHeight = 0;
    
    const constants = {
      headerHeight: 40,
      sectionTitleHeight: 50,
      featureRowHeight: 45,
      subItemRowHeight: 35,
      pageMargin: 40,
      maxContentHeight: 700,
      minItemsPerPage: 1
    };

    // Initialize first page with header height
    currentHeight = constants.headerHeight + constants.sectionTitleHeight;

    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const featureHeight = constants.featureRowHeight + 
                           (feature.subItems.length * constants.subItemRowHeight);

      // If this feature would exceed page height
      if (currentHeight + featureHeight > constants.maxContentHeight) {
        // Only create new page if we have content for current page
        if (currentPage.length > 0) {
          pages.push(currentPage);
          currentPage = [];
          currentHeight = constants.headerHeight; // Reset height for new page
        }

        // Handle large features that need splitting
        if (featureHeight > constants.maxContentHeight) {
          const maxItemsForPage = Math.floor(
            (constants.maxContentHeight - constants.featureRowHeight) / constants.subItemRowHeight
          );

          // Split the feature
          const firstPart: EstimationItem = {
            feature: feature.feature,
            subItems: feature.subItems.slice(0, maxItemsForPage)
          };

          const secondPart: EstimationItem = {
            feature: `${feature.feature} (continued)`,
            subItems: feature.subItems.slice(maxItemsForPage)
          };

          // Add first part to current page
          currentPage.push(firstPart);
          pages.push(currentPage);

          // Start new page with second part
          currentPage = [secondPart];
          currentHeight = constants.headerHeight + 
                         constants.featureRowHeight + 
                         (secondPart.subItems.length * constants.subItemRowHeight);
        } else {
          // Feature fits on a new page
          currentPage.push(feature);
          currentHeight = constants.headerHeight + featureHeight;
        }
      } else {
        // Add feature to current page
        currentPage.push(feature);
        currentHeight += featureHeight;
      }
    }

    // Add remaining items if any
    if (currentPage.length > 0) {
      pages.push(currentPage);
    }

    return pages;
  }

  // Helper method to calculate table heights
  private static calculateTableHeight(data: TimelineItem[]): number {
    const headerHeight = 40;
    const rowHeight = 60;
    const bulletPointHeight = 20;
    
    return data.reduce((total, item) => {
      const bulletPoints = [
        ...(item.activities || []),
        ...(item.deliverables || []),
        ...(item.details || [])
      ].length;
      
      return total + rowHeight + (bulletPoints * bulletPointHeight);
    }, headerHeight);
  }

  private static calculateEstimationTableHeight(features: EstimationItem[]): number {
    const headerHeight = 40;
    const rowHeight = 40;
    
    return features.reduce((total, feature) => {
      return total + rowHeight + (feature.subItems.length * rowHeight);
    }, headerHeight);
  }

  private static calculateSummaryTableHeight(summary: EstimationSummary): number {
    const headerHeight = 40;
    const rowHeight = 40;
    
    return headerHeight + 
      (summary.breakdowns.length * rowHeight) + 
      rowHeight; // Extra row for total
  }

  // Helper method to check if line is a main section header
  private static isMainSectionHeader(line: string): boolean {
    return (
      line.match(/^##?\s*\d+\.\s[A-Z]/) !== null ||    // ## 1. Introduction
      line.match(/^##?\s*\d+\s[A-Z]/) !== null ||      // ## 1 Introduction
      line.match(/^\d+\.\s[A-Z]/) !== null ||          // 1. Introduction
      line.match(/^\d+\s[A-Z]/) !== null ||            // 1 Introduction
      line.match(/^##?\s*\d+\.?\s*$/) !== null         // ## 1. or ## 1
    );
  }

  // Helper method to check if line is a specific main section
  private static isSpecificMainSection(line: string): boolean {
    const mainSectionTitles = [
      'Introduction',
      'Functional Requirements',
      'Integration Requirements',
      'Development Environment and Tools',
      'Project Timeline and Milestones',
      'User Stories',
      'UI/UX Design Plan',
      'Test Cases',
      'Conclusion and Next Steps',
    ];

    return mainSectionTitles.some(title => 
      line.match(new RegExp(`^##?\\s*\\d+\\.\\s*${title}$`)) ||  // ## 1. Introduction
      line.match(new RegExp(`^\\d+\\.\\s*${title}$`))            // 1. Introduction
    );
  }

 
} 